import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Companie, Product } from '__generated__/graphql'

type Props = {
  companie: Pick<Companie, 'id' | 'isProductEnabledForSeller'> & {
    ownedProducts: Array<Pick<Product, 'id'>>
  }
}
const SellerVerificationRequestSubmitted = (props: Props) => {
  const productId = props.companie.ownedProducts[0].id
  const isMarketplaceProduct = props.companie.isProductEnabledForSeller
  return (
    <>
      <div className="titleStepKYC">Thank you for your submission!</div>
      <div
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        {isMarketplaceProduct ? (
          <>
            <p>
              We have received your Seller application and will review it asap. Once your application is approved, your product
              will be discoverable by hundreds of thousands of SaaS buyers.
            </p>
            <span className="tal">
              <b className="tal black2">What{`'`}s next?</b>
              <p>
                While we review your submission, you can enhance your Seller profile by collecting Reviews. Invite your happy
                customers to write reviews for your product. The more Reviews you have, the higher visibility your product will
                get, making it more discoverable.
              </p>
            </span>
            <div style={{ maxWidth: '350px', margin: '0 auto' }}>
              <Link className="link" to="/seller/reviewRequest">
                <Button id="createBuyerCompany" size="medium" sx={{ width: '100%' }} color="primary" variant="contained">
                  Invite Reviewers
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <p>Now you need to create a Perk Offer that will be available to various ecosystems.</p>
            <div style={{ maxWidth: '350px', margin: '0 auto' }}>
              <Link className="link" to={`/seller/perks/${productId}`}>
                <Button id="createBuyerCompany" size="medium" sx={{ width: '100%' }} color="primary" variant="contained">
                  Go to My Perk Offer
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default SellerVerificationRequestSubmitted
