import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import { useAppContextWithCompany } from 'components/AppContext'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import client from 'providers/apollo'

const MUTATION = gql(/* GraphQL */ `
  mutation submitPaymentCardVerification_SubmitPaymentCardVerification($sourceId: String!) {
    submitPaymentCardVerification(sourceId: $sourceId) {
      id
    }
  }
`)

type Props = {
  sourceId: string
  onSucceed: () => void
}

const SubmitPaymentCardVerification = (props: Props) => {
  const showErrors = useShowErrors()
  const context = useAppContextWithCompany()
  const [message, setMessage] = React.useState('')

  const [submitPaymentCardVerification] = useMutation(MUTATION)

  const [loading, setLoading] = React.useState(false)

  const submitPaymentCardVerificationF = async () => {
    setLoading(true)
    try {
      const defaultSource = await submitPaymentCardVerification({
        variables: {
          sourceId: props.sourceId,
        },
      })

      setLoading(false)
      if (defaultSource) {
        context.openSnackbar('Payment Source successfully set as primary', 'success')
        await client.resetStore()
        props.onSucceed()
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      showErrors(e, setMessage)
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="submitPaymentCardVerification"
        id="id-ButtonSubmitPaymentCardVerification"
        icon=""
        color="primary"
        disabled={false}
        variant="outlined"
        size="medium"
        buttonText="Verify card"
        buttonLoadingText="Loading..."
        onClick={submitPaymentCardVerificationF}
        loading={loading}
      />
      {message && (
        <div id="idMessage" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default SubmitPaymentCardVerification
