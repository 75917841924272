import React from 'react'
import { Companie } from '__generated__/graphql'
import { Button } from '@mui/material'
import SellerPaymentFormChild from './SellerPaymentFormChild'

type Props = {
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'typeCompanie'
    | 'requestCardVerification'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const SellerPaymentForm = (props: Props) => {
  const [step, setStep] = React.useState(0)

  return (
    <>
      <div className="titleStepKYC">NachoNacho Fees</div>

      <div style={{ maxWidth: '500px', margin: '40px auto 0', fontSize: '16px' }}>
        {step === 0 && (
          <div>
            <b>Listing Fee</b>: $500/year. Promoted to all NachoNacho signed up customers in-app and on the web. Based on AI
            powered matchmaking.
            <div style={{ marginTop: 20, marginBottom: 20 }}>OR</div>
            <div>
              <b>Booster Fee</b>: Listing in the SaaS/AI Marketplace combined with an extensive marketing boost to the entire
              NachoNacho network. One time fee. Two packages available: $3k or $5k.{' '}
              <a className="link" href="https://nachonacho.com/contact" target="_blank" rel="noreferrer">
                Contact us
              </a>{' '}
              to learn more. Includes waiver of first year's Listing Fee.
            </div>
            <div style={{ marginTop: 30 }}>
              <Button color="primary" variant="contained" size="medium" onClick={() => setStep(1)}>
                Next
              </Button>
            </div>
          </div>
        )}
        {step === 1 && <SellerPaymentFormChild onUpdate={props.onUpdate} companie={props.companie} />}
      </div>
    </>
  )
}
export default SellerPaymentForm
