import { useQuery } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import React from 'react'
import { gql } from '__generated__'
import { SellerKYCContext, SellerKYCContextType, SellerKYCStep, SellerPerkKYCStep } from './SellerKYCContext'

const KYC_QUERY = gql(/* GraphQL */ `
  query getSellerKYCStatus_SellerKYCContextProvider($companieId: String!) {
    getSellerKYCStatus(companieId: $companieId) {
      personalPhoneNumber
      companyDetails
      companyAddress
      businessDetails
      targetMarket
      productDetails
      revShare
      payment
      # paymentSource
      submitForm
    }
  }
`)

type Props = {
  children: React.ReactNode
}

const productStepsMap: SellerKYCStep[] = [
  'personalPhoneNumber',
  'companyDetails',
  'companyAddress',
  'businessDetails',
  'targetMarket',
  'productDetails',
  'revShare',
  'payment',
  // 'paymentSource',
  'submitForm',
  'requestSent',
]

const perkStepsMap: SellerPerkKYCStep[] = [
  'personalPhoneNumber',
  'companyDetails',
  'companyAddress',
  'businessDetails',
  'targetMarket',
  'productDetails',
  'submitForm',
  'requestSent',
]

const SellerKYCContextProvider = (props: Props) => {
  const [step, setStep] = React.useState(0)
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [stepsMap, setStepsMap] = React.useState(productStepsMap)
  const [sellerType, setSellerType] = React.useState<'product' | 'perk'>('product')

  const appContext = useAppContextWithCompany()
  const { data, loading, error, refetch } = useQuery(KYC_QUERY, {
    variables: { companieId: appContext.userRoleCompanie.companie.id },
  })
  if (loading) return <></>
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (!data) return <NotFound />

  const filledSteps = data.getSellerKYCStatus
  if (firstLoad) {
    setFirstLoad(false)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    setStep(firstUnfilled)
  }
  const nextStep = () => {
    if (step < stepsMap.length - 1) {
      setStep(step + 1)
    }
  }
  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }
  const setCurrentStep = (kycStep: SellerKYCStep) => {
    if (step === stepsMap.length - 1) {
      return
    }
    const index = Object.keys(stepsMap).findIndex((key) => stepsMap[key] === kycStep)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    if (index <= firstUnfilled) {
      setStep(index)
    }
  }
  const getStepStatus = (kycStep: SellerKYCStep) => {
    if (!filledSteps[kycStep]) {
      return false
    }
    const index = Object.keys(stepsMap).findIndex((key) => stepsMap[key] === kycStep)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    if (index < firstUnfilled) {
      return true
    }
    return false
  }

  const setSellerTypeLogic = (sellerType: 'product' | 'perk') => {
    if (sellerType === 'product') {
      setStepsMap(productStepsMap)
    } else {
      setStepsMap(perkStepsMap)
    }
    setSellerType(sellerType)
    setFirstLoad(true)
  }

  const context: SellerKYCContextType = {
    filledSteps,
    sellerType,
    currentStep: () => stepsMap[step],
    setCurrentStep,
    nextStep,
    previousStep,
    setSellerType: setSellerTypeLogic,
    getStepStatus,
    reloadKYCState: refetch,
  }

  return <SellerKYCContext.Provider value={context}>{props.children}</SellerKYCContext.Provider>
}

export default SellerKYCContextProvider
