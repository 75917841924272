import React from 'react'
import { ReactComponent as FilledProgressCircle } from 'assets/icon/filledProgressCircle.svg'
import { ReactComponent as EmptyProgressCircle } from 'assets/icon/emptyProgressCircle.svg'
import { MavenKYCStep, useMavenKYCContext } from './MavenKYCContext'
import { Box } from '@mui/material'
import { EntityType } from '__generated__/graphql'

type Step = {
  value: MavenKYCStep
  label: string
  hideFreelancer?: boolean
}

const personalSteps: Step[] = [
  { value: 'personalDetails', label: 'About you' },
  { value: 'personalLocation', label: 'Your location' },
  { value: 'mavenType', label: 'Maven type' },
]

const companieSteps: Step[] = [
  { value: 'companyDetails', label: 'About Maven', hideFreelancer: true },
  { value: 'mavenAddress', label: 'Maven address' },
  { value: 'basicInformation', label: 'Basic information' },
  { value: 'serviceCategories', label: 'Service categories' },
  { value: 'socialMedia', label: 'Social media' },
  { value: 'productDescription', label: 'Maven profile' },
  { value: 'valueProposition', label: 'Value proposition' },
  { value: 'pricing', label: 'Your Pricing' },
  { value: 'targetCustomers', label: 'Target customers' },
  // { value: 'payment', label: 'NachoNacho Fees' },
  { value: 'submitForm', label: 'Confirm submission' },
]
type Props = {
  entityType?: EntityType | null
}

const MavenKYCStepsProgress = (props: Props) => {
  const mavenKYCContext = useMavenKYCContext()
  const getStepStatus = (step) => {
    return mavenKYCContext.getStepStatus(step)
  }
  const stepStyle = { display: 'flex', alignItems: 'center', margin: '20px 0 0 -12.5px' }
  const showStep = (step: Step) => {
    return !step.hideFreelancer || props.entityType === EntityType.Company || props.entityType === EntityType.Fractional
  }
  return (
    <Box width="321px" ml="50px">
      <Box className="fw-700">Personal</Box>
      <Box m="10px 12px" pl="40px" borderLeft="1px dashed black">
        <Box borderLeft="1px dashed black">
          {personalSteps.map((step) => (
            <>
              {showStep(step) && (
                <Box style={stepStyle} key={step.value}>
                  <Box width="fit-content" bgcolor="#FAF8F7" mr="16px">
                    {getStepStatus(step.value) ? <FilledProgressCircle /> : <EmptyProgressCircle />}
                  </Box>
                  <Box
                    style={{
                      cursor: getStepStatus(step.value) ? 'pointer' : 'unset',
                      fontWeight: mavenKYCContext.currentStep() === step.value ? 700 : 400,
                    }}
                    onClick={() => mavenKYCContext.setCurrentStep(step.value)}
                  >
                    {step.label}
                  </Box>
                </Box>
              )}
            </>
          ))}
        </Box>
      </Box>

      <Box className="fw-700">Company</Box>
      <Box m="10px 12px" pl="40px" borderLeft="1px dashed black">
        <Box borderLeft="1px dashed black">
          {companieSteps.map((step) => (
            <>
              {showStep(step) && (
                <Box style={stepStyle} key={step.value}>
                  <Box width="fit-content" bgcolor="#FAF8F7" mr="16px">
                    {getStepStatus(step.value) ? <FilledProgressCircle /> : <EmptyProgressCircle />}
                  </Box>
                  <Box
                    style={{
                      cursor: getStepStatus(step.value) ? 'pointer' : 'unset',
                      fontWeight: mavenKYCContext.currentStep() === step.value ? 700 : 400,
                    }}
                    onClick={() => mavenKYCContext.setCurrentStep(step.value)}
                  >
                    {step.label}
                  </Box>
                </Box>
              )}
            </>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default MavenKYCStepsProgress
