import React from 'react'
import OnboardingCompanieSellerForm from './OnboardingCompanieSellerForm'
import OnboardingConfirmationFormSeller from './OnboardingConfirmationFormSeller'
import OnboardingCompanieSellerFormRevshare from './OnboardingCompanieSellerFormRevshare'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { useAppContextWithCompany } from 'components/AppContext'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import { Button, Paper, Theme, useMediaQuery } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { URL_HOME } from 'config/config'
import OnboardingSellerProduct from './OnboardingSellerProduct'
import { useSellerKYCContext } from 'components/onboarding/kyc/seller/SellerKYCContext'
import SellerKYCStepsProgress from 'components/onboarding/kyc/seller/SellerKYCStepsProgress'
import SellerPaymentForm from 'components/onboarding/kyc/seller/payment/SellerPaymentForm'
import SellerCompanieAddresssForm from 'components/onboarding/SellerCompanieAddressForm'
import OnboardingCompanieSellerDetailsForm from './OnboardingCompanieSellerDetailsForm'
import SellerVerificationRequestSubmitted from 'components/onboarding/SellerVerificationRequestSubmitted'
// import SellerPaymentSourceForm from './SellerPaymentSourceForm'
import SellerTargetMarketForm from './SellerTargetMarketForm'
import OnboardingValidaterPhoneUserQuery from 'components/onboarding/OnboardingValidaterPhoneUserQuery'

const QUERY = gql(/* GraphQL */ `
  query companie_OnboardingLogicCompanieSeller($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
      registeredBusinessName
      website
      typeCompanie
      typeBusinessStructure
      questionPricingPage
      questionPayingCustomers
      questionHowManyUsersPerAccount
      questionHowGeographiesInterest
      questionSizeCustomersTargeting
      questionUserAcquisitionChannelsDoYouUseToday
      statusVerification
      requestCardVerification
      sellerARR
      recommendationSellerRevshare
      addStripeBank
      incomingPaymentFeeACHCard
      hideDebitCard
      incomingPaymentFeeDebitCard
      hideDebitCredit
      incomingPaymentFeeCreditCard
      isProductEnabledForSeller
      isPerkEnabledForSeller
      ownedProducts {
        id
        isIcpAllIndustries
        isIcpAllLocations
      }
    }
  }
`)
type Props = {
  handleClose: () => void
}

const OnboardingLogicCompanieSeller = (props: Props) => {
  const context = useAppContextWithCompany()
  const sellerKYCContext = useSellerKYCContext()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const companieData = useQuery(QUERY, {
    variables: { where: { id: context.userRoleCompanie.companie.id } },
  })

  if (companieData.error)
    return <Error message={companieData.error.graphQLErrors.length ? companieData.error.graphQLErrors[0].message : ''} />

  if (companieData.loading) return <Loading />
  if (!companieData.data?.companie) return <NotFound />
  const companie = companieData.data.companie
  const isMarketplaceProduct = companie.isProductEnabledForSeller

  if (!isMarketplaceProduct && sellerKYCContext.sellerType === 'product') {
    sellerKYCContext.setSellerType('perk')
  }
  const KYCPages = (
    <>
      {sellerKYCContext.currentStep() === 'personalPhoneNumber' && (
        <OnboardingValidaterPhoneUserQuery userId={context.me.id} onUpdate={() => sellerKYCContext.nextStep()} />
      )}
      {sellerKYCContext.currentStep() === 'companyDetails' && (
        <OnboardingCompanieSellerForm onUpdate={() => sellerKYCContext.nextStep()} onCancel={() => {}} companie={companie} />
      )}
      {sellerKYCContext.currentStep() === 'companyAddress' && (
        <SellerCompanieAddresssForm
          companieId={companie.id}
          userId={context.me.id}
          onCreate={() => sellerKYCContext.nextStep()}
          onUpdate={() => sellerKYCContext.nextStep()}
          onCancel={() => sellerKYCContext.previousStep()}
        />
      )}
      {sellerKYCContext.currentStep() === 'businessDetails' && (
        <OnboardingCompanieSellerDetailsForm
          onUpdate={() => sellerKYCContext.nextStep()}
          onCancel={() => {}}
          companie={companie}
        />
      )}
      {sellerKYCContext.currentStep() === 'productDetails' && (
        <OnboardingSellerProduct onUpdate={() => sellerKYCContext.nextStep()} companie={companie} />
      )}
      {sellerKYCContext.currentStep() === 'targetMarket' && (
        <SellerTargetMarketForm onUpdate={() => sellerKYCContext.nextStep()} product={companie.ownedProducts[0]} />
      )}
      {sellerKYCContext.currentStep() === 'revShare' && (
        <OnboardingCompanieSellerFormRevshare
          onUpdate={() => sellerKYCContext.nextStep()}
          onCancel={() => sellerKYCContext.previousStep()}
          companie={companie}
        />
      )}
      {/* {sellerKYCContext.currentStep() === 'paymentSource' && (
        <SellerPaymentSourceForm onUpdate={() => sellerKYCContext.nextStep()} companie={companie} />
      )} */}
      {sellerKYCContext.currentStep() === 'submitForm' && (
        <OnboardingConfirmationFormSeller onUpdate={() => sellerKYCContext.nextStep()} companie={companie} />
      )}
      {sellerKYCContext.currentStep() === 'requestSent' && <SellerVerificationRequestSubmitted companie={companie} />}
      {sellerKYCContext.currentStep() === 'payment' && (
        <SellerPaymentForm companie={companie} onUpdate={() => sellerKYCContext.nextStep()} />
      )}
    </>
  )
  return (
    <div
      style={{
        width: isMobile ? 'unset' : '1100px',
        maxWidth: '100vw',
        height: '100vh',
        background: '#FAF8F7',
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <div
        style={{
          width: '100%',
          background: '#2475FF',
          height: '100px',
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 35px',
          alignItems: 'center',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <span style={{ fontSize: '21px' }}>Seller account setup</span>
        <Button
          onClick={props.handleClose}
          variant="outlined"
          sx={{ fontFamily: 'Roobert', fontSize: 16, borderRadius: '20px', lineHeight: 1 }}
        >
          <LogoutIcon style={{ height: '18px', marginRight: '5px' }} />
          {sellerKYCContext.filledSteps['submitForm'] ? 'Close' : 'Continue later'}
        </Button>
      </div>
      <div style={{ width: '100%', overflow: 'scroll', height: '100vh' }}>
        <div
          style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', padding: '38px', paddingTop: '140px' }}
        >
          {isMobile ? (
            <div>{KYCPages}</div>
          ) : (
            <Paper variant="outlined" className="paperIn" style={{ background: 'white', paddingTop: '60px', flex: 1 }}>
              {KYCPages}
            </Paper>
          )}
          <div>
            {isMobile ? (
              <></>
            ) : (
              <>
                <SellerKYCStepsProgress />
                <Button
                  variant="outlined"
                  sx={{ fontFamily: 'Roobert', borderRadius: '20px', margin: '50px 0 0 50px' }}
                  target="_blank"
                  href={`${URL_HOME}/seller-meeting`}
                >
                  Request a video call
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingLogicCompanieSeller
