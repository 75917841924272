import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation createInvoiceSellerListingFeePayment_CreateInvoiceSellerListingFeePayment(
    $companieId: String!
    $promoCodeGlobalId: String
  ) {
    createInvoiceSellerListingFeePayment(companieId: $companieId, promoCodeGlobalId: $promoCodeGlobalId) {
      id
    }
  }
`)

type Props = {
  promoCodeGlobalId?: string
  companieId: string
  disabled: boolean
  onCreate: () => void
}

const CreateInvoiceSellerListingFeePayment = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [createInvoiceSellerListingFeePayment] = useMutation(MUTATION)

  const [message, setMessage] = React.useState('')
  const createInvoiceSellerListingFeePaymentF = async () => {
    setMessage('')
    setLoading(true)

    try {
      const data = await createInvoiceSellerListingFeePayment({
        variables: {
          companieId: props.companieId,
          promoCodeGlobalId: props.promoCodeGlobalId,
        },
      })

      setMessage('Ok!')
      if (data) {
        console.log('data', data)
        props.onCreate()
      }
    } catch (e) {
      showErrors(e)
    }

    setLoading(false)
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="createInvoiceSellerListingFeePayment"
        id="createInvoiceSellerListingFeePaymentFId"
        color="primary"
        // variant="outlined"
        size="medium"
        buttonText="Pay Now"
        // size="medium"
        sx={{ width: '100%' }}
        // color="primary"
        variant="contained"
        onClick={createInvoiceSellerListingFeePaymentF}
        disabled={props.disabled}
        icon=""
        buttonLoadingText="Setting up..."
        loading={loading}
      />

      <p className="secondary">{message}</p>
    </>
  )
}

export default CreateInvoiceSellerListingFeePayment
