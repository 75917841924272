import React from 'react'
import { Button } from '@mui/material'
import VerifySourceCard from './VerifySourceCard'
import { Source } from '__generated__/graphql'
import SubmitPaymentCardVerification from './SubmitPaymentCardVerification'

type Props = {
  mode: string
  setMode: (mode: string) => void
  source: Pick<
    Source,
    | 'id'
    | 'typeSource'
    | 'object'
    | 'country'
    | 'statusVerificationSource'
    | 'status'
    | 'funding'
    | 'brand'
    | 'last4'
    | 'exp_month'
    | 'exp_year'
    | 'bank_name'
    | 'nickname'
    | 'name'
    | 'isDefaultSource'
  >
}

const SingleSourceVerificationCard = (props: Props) => {
  return (
    <>
      {props.source.typeSource === 'STRIPE' && props.source.object === 'card' && (
        <>
          {props.source.statusVerificationSource === 'SUBMITED' && (
            <>
              <div style={{ height: '10px' }} />
              {props.mode === '' && (
                <>
                  <div className="secondary">
                    We charged your card $1.00. Please log into your card account, and find the Card Verification Code from the
                    statement descriptor next to the $1.00 charge. It starts with NN and has 4 further characters.
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Button variant="outlined" color="primary" onClick={() => props.setMode('verifyCard')}>
                      Verify account
                    </Button>
                  </div>
                </>
              )}

              {props.mode === 'verifyCard' && (
                <VerifySourceCard
                  onCancel={() => props.setMode('')}
                  onVerified={() => props.setMode('')}
                  sourceId={props.source.id}
                />
              )}
            </>
          )}

          {props.source.statusVerificationSource === 'REQUIRED' && (
            <div style={{ marginTop: 10 }}>
              <SubmitPaymentCardVerification sourceId={props.source.id} onSucceed={() => {}} />
            </div>
          )}
          {props.source.statusVerificationSource === 'APPROVED' && <div>Source verified</div>}
          {props.source.statusVerificationSource === 'NOT_REQUIRED' && <div>Source verified</div>}
        </>
      )}
    </>
  )
}

export default SingleSourceVerificationCard
