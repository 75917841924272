import React from 'react'
import { Companie } from '__generated__/graphql'
import { Button } from '@mui/material'
import MavenPaymentFormChild from './MavenPaymentFormChild'
import { useMavenKYCContext } from '../MavenKYCContext'

type Props = {
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'typeCompanie'
    | 'requestCardVerification'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const MavenPaymentForm = (props: Props) => {
  const mavenKYCContext = useMavenKYCContext()
  // const [step, setStep] = React.useState(0)

  return (
    <>
      <div className="titleStepKYC">Referral Fees</div>

      <div style={{ maxWidth: '500px', margin: '40px auto 0', fontSize: '16px' }}>
        {/* {step === 0 && ( */}
        <div>
          <div>
            <b>Referral Fees</b>: Charged only if/when a payment is made to you by a customer:
            <ul style={{ lineHeight: '8px', marginTop: 6, marginBottom: 20 }}>
              <li>15% on the first $10k</li>
              <li>10% on the next $10k</li>
              <li>5% thereafter</li>
            </ul>
          </div>

          <div style={{ marginTop: 30 }}>
            <Button
              color="primary"
              sx={{ width: '100%' }}
              variant="contained"
              size="medium"
              onClick={() => {
                mavenKYCContext.nextStep()
              }}
            >
              Next
            </Button>
          </div>
        </div>
        {/* )} */}
        {/* {step === 1 && <MavenPaymentFormChild onUpdate={props.onUpdate} companie={props.companie} />} */}
      </div>
    </>
  )
}
export default MavenPaymentForm
