import retry from './retryHelper'
import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Pwa from 'components/pwa/Pwa'
const HomePage = lazy(() => retry(() => import('../nav/home/btob/HomePage')) as any)
const UnsubscribePage = lazy(() => retry(() => import('../user/UnsubscribePage')) as any)
const PrivateRoute = lazy(() => retry(() => import('./PrivateRoute')) as any)
const NotLoginRoute = lazy(() => retry(() => import('./NotLoginRoute')) as any)
const AdminRoute = lazy(() => retry(() => import('./AdminRoute')) as any)
const EventFeedPage = lazy(() => retry(() => import('components/event/EventFeedPage')) as any)
const MarketplacePage = lazy(() => retry(() => import('../marketplace/buyer/MarketplacePage')) as any)
const MarketplaceTribePage = lazy(() => retry(() => import('../marketplace/tribe/MarketplaceTribePage')) as any)
const DownloadFilesPage = lazy(() => retry(() => import('../downloadFile/list/DownloadFilesPage')) as any)

const UserProfileDetailsAdminPage = lazy(
  () => retry(() => import('../user/single/profile/sectionDetails/UserProfileDetailsAdminPage')) as any,
)

const LoginPage = lazy(() => retry(() => import('../user/auth/login/LoginPage')) as any)
const SignupNachoCard = lazy(() => retry(() => import('../user/auth/signup/SignupNachoCard')) as any)
const SignupPartnerPage = lazy(() => retry(() => import('../user/auth/signup/partner/SignupPartnerPage')) as any)
const SubscribePartner = lazy(() => retry(() => import('../user/auth/signup/partner/SubscribePartner')) as any)
const SubscribePerkPartner = lazy(() => retry(() => import('../user/auth/signup/partner/SubscribePerkPartner')) as any)
const SignupPartnerPageTribe = lazy(() => retry(() => import('../user/auth/signup/partner/SignupPartnerPageTribe')) as any)
const NotFound = lazy(() => retry(() => import('../nav/error/NotFound')) as any)
const InvoicesTribePage = lazy(() => retry(() => import('../invoice/list/tribe/InvoicesTribePage')) as any)

const DashboardPageAdmin = lazy(() => retry(() => import('../nav/home/btob/DashboardPageAdmin')) as any)
const CategoriePage = lazy(() => retry(() => import('../categorie/single/CategoriePage')) as any)

const ChangePhonePage = lazy(() => retry(() => import('../user/single/phone/ChangePhonePage')) as any)
const ProductPageSeller = lazy(() => retry(() => import('../product/single/page/ProductPageSeller')) as any)
const SellerAnalyticPage = lazy(() => retry(() => import('../product/single/page/SellerAnalyticPage')) as any)
// const ProductPageMaven = lazy(() => retry(() => import('../product/single/page/maven/ProductPageMaven')) as any)
const SubscribeToProduct = lazy(() => retry(() => import('../product/single/page/subscribe/SubscribeToProduct')) as any)
const ProductActivityPage = lazy(() => retry(() => import('../product/single/page/ProductActivityPage')) as any)

const ProductTribePage = lazy(() => retry(() => import('../product/single/page/tribe/ProductTribePage')) as any)
const BalanceAdmin = lazy(() => retry(() => import('../invoice/list/BalanceAdmin')) as any)

const SubscriptionPage = lazy(() => retry(() => import('../subscription/single/page/SubscriptionPage')) as any)
const SubscriptionSellerPage = lazy(() => retry(() => import('../subscription/single/page/SubscriptionSellerPage')) as any)
const InvoicePage = lazy(() => retry(() => import('../invoice/single/invoicePage/InvoicePage')) as any)

const PaymentSourcePage = lazy(() => retry(() => import('../paymentSource/list/PaymentSourcePage')) as any)
const PaymentSourceSellerPage = lazy(() => retry(() => import('../paymentSource/list/PaymentSourceSellerPage')) as any)
const PaymentSourceMavenPage = lazy(() => retry(() => import('../paymentSource/list/PaymentSourceMavenPage')) as any)
const CashbackPage = lazy(() => retry(() => import('../balance/CashbackPage')) as any)
const ChatPage = lazy(() => retry(() => import('../chat/ChatPage')) as any)
const PostPaidStatementsPage = lazy(() => retry(() => import('../postPaidStatement/list/PostPaidStatementsPage')) as any)
const InvoicesUserPage = lazy(() => retry(() => import('../invoice/list/InvoicesUserPage')) as any)
const InvoicesPage = lazy(() => retry(() => import('../invoice/list/InvoicesPage')) as any)
const InvoicesCompaniePage = lazy(() => retry(() => import('../invoice/list/InvoicesCompaniePage')) as any)
const TribePartnerPage = lazy(() => retry(() => import('../companie/single/TribePartnerPage')) as any)
const InvoicesSellerPage = lazy(() => retry(() => import('../invoice/list/seller/InvoicesSellerPage')) as any)
const SubscriptionsCompanyPage = lazy(
  () => retry(() => import('../subscription/list/subscriptionCompanie/SubscriptionsCompanyPage')) as any,
)

const SubscriptionsTribePage = lazy(() => retry(() => import('../subscription/list/tribe/SubscriptionsTribePage')) as any)

const ProductsTribePage = lazy(() => retry(() => import('../product/list/tribe/ProductsTribePage')) as any)
const ProductsBuyerPage = lazy(() => retry(() => import('../product/list/buyer/ProductsBuyerPage')) as any)

const CreatePerkTribePage = lazy(() => retry(() => import('../perk/single/tribe/CreatePerkTribePage')) as any)
const PerkPageTribe = lazy(() => retry(() => import('../perk/single/tribe/PerkPageTribe')) as any)
const PerkDetailPageTribe = lazy(() => retry(() => import('../perkDetail/single/PerkDetailPageTribe')) as any)
const PerkDetailPageSeller = lazy(() => retry(() => import('../perkDetail/single/PerkDetailPageSeller')) as any)
const PerkPageSeller = lazy(() => retry(() => import('../perk/single/seller/PerkPageSeller')) as any)
const MarketingAssets = lazy(() => retry(() => import('../marketingToolkit/MarketingToolkitPage')) as any)
const ProductPageMavenProfilePage = lazy(
  () => retry(() => import('../product/single/page/maven/ProductPageMavenProfilePage')) as any,
)
const PerksSellerPage = lazy(() => retry(() => import('../perk/list/seller/PerksSellerPage')) as any)
const UserProfilePage = lazy(() => retry(() => import('../user/single/profile/UserProfilePage')) as any)
const UserCompaniePage = lazy(() => retry(() => import('../user/single/profile/UserCompaniePage')) as any)
const UserIssuedCardsPage = lazy(() => retry(() => import('../issuedCard/list/issuedCardsUser/UserIssuedCardsPage')) as any)

const IssuedCardPage = lazy(() => retry(() => import('../issuedCard/single/IssuedCardPage')) as any)
const IssuedCardsCompaniePage = lazy(
  () => retry(() => import('../issuedCard/list/issuedCardsCompanie/IssuedCardsCompaniePage')) as any,
)
const IssuedCardsPage = lazy(() => retry(() => import('../issuedCard/list/IssuedCardsPage')) as any)
const ReviewsPage = lazy(() => retry(() => import('../review/list/ReviewsPage')) as any)
const CreateCompanyPage = lazy(() => retry(() => import('../companie/single/CreateCompanyPage')) as any)
const DisputeIssuingsCompaniePage = lazy(() => retry(() => import('../disputeIssuing/list/DisputeIssuingsCompaniePage')) as any)
const InviteBuyerRedirectPage = lazy(() => retry(() => import('../invitation/InviteBuyerRedirectPage')) as any)
const ReviewRequestSellerPage = lazy(
  () => retry(() => import('../reviewRequest/list/seller/maven/ReviewRequestSellerPage')) as any,
)
const SpecialOffersMavenPage = lazy(() => retry(() => import('../promotion/specialOffer/SpecialOffersMavenPage')) as any)
const MavenQuotationsPage = lazy(() => retry(() => import('../quotation/MavenQuotationsPage')) as any)
const ReviewRequestMavenPage = lazy(() => retry(() => import('../reviewRequest/list/seller/maven/ReviewRequestMavenPage')) as any)
const ServicePartnerSellerPage = lazy(
  () => retry(() => import('../productsRelationship/list/seller/ServicePartnerSellerPage')) as any,
)
const AuthLinkedin = lazy(() => retry(() => import('../user/auth/linkedin/AuthLinkedin')) as any)
const AuthGoogle = lazy(() => retry(() => import('../user/auth/google/AuthGoogle')) as any)
const AuthSlackPage = lazy(() => retry(() => import('../user/auth/linkedin/AuthSlackPage')) as any)
const QuickbooksListener = lazy(() => retry(() => import('components/quickbooks/QuickbooksListener')) as any)

const UserRoleCompaniesPageTribe = lazy(
  () => retry(() => import('../userRoleCompanie/list/tribe/UserRoleCompaniesPageTribe')) as any,
)

const CompaniesTribePageQuery = lazy(() => retry(() => import('../companie/list/tribe/CompaniesTribePageQuery')) as any)
const TribeRedeemOfferPage = lazy(() => retry(() => import('../tribePortCoRedeem/TribeRedeemOfferPage')) as any)
const BuyerRedeemOfferPage = lazy(() => retry(() => import('../tribePortCoRedeem/BuyerRedeemOfferPage')) as any)

const CompaniePortCoTribePage = lazy(() => retry(() => import('../companie/tribe/CompaniePortCoTribePage')) as any)
const CompanieTeamPage = lazy(() => retry(() => import('../user/list/team/CompanieTeamPage')) as any)
const CompanieSellerTeamPage = lazy(() => retry(() => import('../user/list/team/CompanieSellerTeamPage')) as any)
const CompanieMavenTeamPage = lazy(() => retry(() => import('../user/list/team/CompanieMavenTeamPage')) as any)
const CompanieTribeTeamPage = lazy(() => retry(() => import('../user/list/team/tribe/CompanieTribeTeamPage')) as any)
const InvoicesGraphIssuingCategoriesPage = lazy(
  () => retry(() => import('../invoice/list/graph/InvoicesGraphIssuingCategoriesPage')) as any,
)
const SubscriptionsUserPage = lazy(() => retry(() => import('../subscription/list/SubscriptionsUserPage')) as any)
const SubscriptionsCompanySheetPage = lazy(
  () => retry(() => import('../subscription/list/subscriptionsCompanySheet/SubscriptionsCompanySheetPage')) as any,
)
const SubscriptionsCompanyRedirect = lazy(() => retry(() => import('../subscription/SubscriptionsCompanyRedirect')) as any)
const SubscriptionsProductPage = lazy(
  () => retry(() => import('../subscription/list/subscriptionsProduct/SubscriptionsProductPage')) as any,
)

const CompanieSellerRevenueShare = lazy(() => retry(() => import('../sellerBalance/CompanieSellerRevenueShare')) as any)
const CompanieSellerLeads = lazy(() => retry(() => import('../lead/list/CompanieSellerLeads')) as any)
const CompanieMavenLeads = lazy(() => retry(() => import('../lead/list/CompanieMavenLeads')) as any)
const MavenCustomersTabPage = lazy(() => retry(() => import('../product/single/page/maven/MavenCustomersTabPage')) as any)

const SherlockPage = lazy(() => retry(() => import('../discoveryProductAggregate/list/SherlockPage')) as any)
const ShamanPage = lazy(() => retry(() => import('../productRecommendation/ShamanPage')) as any)

const UserProfileRedirect = lazy(() => retry(() => import('components/user/single/profile/UserProfileRedirect')) as any)
const Financing = lazy(() => retry(() => import('components/financing/Financing')) as any)

const Settings = lazy(() => retry(() => import('components/nav/page/settings/Settings')) as any)

const AppsRedirect = lazy(() => retry(() => import('components/nav/page/settings/AppsRedirect')) as any)

const TutorialPage = lazy(() => retry(() => import('components/tutorial/TutorialPage')) as any)

const RouteApp = () => {
  return (
    <Routes>
      <Route path="/application" element={<Pwa />} />
      <Route path="/linkedin" element={<AuthLinkedin />} />
      <Route path="/google" element={<AuthGoogle />} />
      <Route path="/slack" element={<AuthSlackPage />} />
      <Route path="/quickbooks" element={<QuickbooksListener />} />
      <Route path="/unsubscribe/:userId" element={<UnsubscribePage />} />
      <Route path="/signupPartner/:partnerHandle/:productUrlName" element={<SubscribePartner />} />
      <Route path="/signupPartnerPerk/:partnerHandle/:productUrlName/:perkId" element={<SubscribePerkPartner />} />
      <Route path="/login" element={<NotLoginRoute component={LoginPage} />} />
      <Route path="/signup/partner/:partnerHandle" element={<NotLoginRoute component={SignupPartnerPage} />} />
      <Route path="/signup/tribe/:partnerHandle" element={<NotLoginRoute component={SignupPartnerPageTribe} />} />
      <Route path="/signupNachoCard" element={<NotLoginRoute component={SignupNachoCard} />} />
      <Route path="/" element={<PrivateRoute component={HomePage} />} />
      <Route path="/downloads/:companieId/:userId" element={<PrivateRoute component={DownloadFilesPage} />} />
      <Route path="/dashboard/:companieId" element={<AdminRoute component={DashboardPageAdmin} />} />
      <Route path="/marketplace" element={<PrivateRoute component={MarketplacePage} />} />
      <Route path="/financing" element={<PrivateRoute component={Financing} />} />
      <Route path="/tribe/marketplace" element={<PrivateRoute component={MarketplaceTribePage} />} />
      <Route path="/vendor/:productId" element={<PrivateRoute component={ProductActivityPage} />} />
      <Route path="/category/:categorieId" element={<PrivateRoute component={CategoriePage} />} />
      <Route path="/seller/team/:companieId" element={<PrivateRoute component={CompanieSellerTeamPage} />} />
      <Route path="/maven/team/:companieId" element={<PrivateRoute component={CompanieMavenTeamPage} />} />
      <Route path="/subscription/:subscriptionId" element={<PrivateRoute component={SubscriptionPage} />} />
      <Route path="/seller/subscription/:subscriptionId" element={<PrivateRoute component={SubscriptionSellerPage} />} />
      <Route path="/issuedCard/:issuedCardId" element={<PrivateRoute component={IssuedCardPage} />} />
      <Route path="/reviews" element={<PrivateRoute component={ReviewsPage} />} />
      <Route path="/issuedCards/:userId" element={<PrivateRoute component={UserIssuedCardsPage} />} />
      <Route path="/issuedCardsCompany/:companieId" element={<PrivateRoute component={IssuedCardsCompaniePage} />} />
      <Route path="/nachoCards" element={<PrivateRoute component={IssuedCardsPage} />} />
      <Route path="/company/createCompany" element={<PrivateRoute component={CreateCompanyPage} />} />
      <Route path="/settings/:settingsTab" element={<PrivateRoute component={Settings} />} />
      <Route path="/apps" element={<PrivateRoute component={AppsRedirect} />} />
      {/* <Route path="/review/:productId" element={<PrivateRoute component={ReviewPage} />} /> */}
      <Route path="/tribe/staff/:companieId" element={<PrivateRoute component={CompanieTribeTeamPage} />} />
      <Route path="/categories/:companieId" element={<PrivateRoute component={InvoicesGraphIssuingCategoriesPage} />} />
      <Route path="/tribe/portfolioCompany/:companieId" element={<PrivateRoute component={CompaniePortCoTribePage} />} />
      <Route path="/tribe/members/:partnerId" element={<PrivateRoute component={UserRoleCompaniesPageTribe} />} />
      <Route path="/tribe/createPerk/:partnerId" element={<PrivateRoute component={CreatePerkTribePage} />} />
      <Route path="/tribe/perk/:perkId" element={<PrivateRoute component={PerkPageTribe} />} />
      <Route path="/seller/perk/:perkId" element={<PrivateRoute component={PerkPageSeller} />} />
      <Route path="/seller/marketingAssets" element={<PrivateRoute component={MarketingAssets} />} />
      <Route path="/maven/marketingAssets" element={<PrivateRoute component={MarketingAssets} />} />
      <Route path="/maven/mainProfile" element={<PrivateRoute component={ProductPageMavenProfilePage} />} />
      <Route path="/maven/specialOffers" element={<PrivateRoute component={SpecialOffersMavenPage} />} />
      <Route path="/maven/quotations" element={<PrivateRoute component={MavenQuotationsPage} />} />
      <Route path="/tribe/product/:productId/:partnerId" element={<PrivateRoute component={ProductTribePage} />} />
      <Route path="/tribe/vendors/:partnerId" element={<PrivateRoute component={ProductsTribePage} />} />
      <Route path="/vendors" element={<PrivateRoute component={ProductsBuyerPage} />} />
      <Route path="/tribe/invitations/:partnerId" element={<PrivateRoute component={TribePartnerPage} />} />
      <Route path="/tribe/subscriptions/:partnerId" element={<PrivateRoute component={SubscriptionsTribePage} />} />
      <Route path="/tribe/redemptions/:partnerId" element={<PrivateRoute component={TribeRedeemOfferPage} />} />
      <Route path="/redeems" element={<PrivateRoute component={BuyerRedeemOfferPage} />} />
      {/* <Route path="/seller/redeems/" element={<PrivateRoute component={TribePortCoRedeemSellerPageQuery} />} /> */}
      <Route path="/tribe/companies/:partnerId/:companieId" element={<PrivateRoute component={CompaniesTribePageQuery} />} />
      <Route path="/team" element={<PrivateRoute component={CompanieTeamPage} />} />
      <Route path="/user/:userId" element={<PrivateRoute component={UserProfilePage} />} />
      <Route path="/userCompany/:userId/:companieId" element={<PrivateRoute component={UserCompaniePage} />} />
      <Route path="/tribe/transactions/:partnerId" element={<PrivateRoute component={InvoicesTribePage} />} />
      <Route path="/paymentSource/:companieId" element={<PrivateRoute component={PaymentSourcePage} />} />
      <Route path="/seller/paymentSource/:companieId" element={<PrivateRoute component={PaymentSourceSellerPage} />} />
      <Route path="/maven/paymentSource/:companieId" element={<PrivateRoute component={PaymentSourceMavenPage} />} />
      <Route path="/invoice/:invoiceId" element={<PrivateRoute component={InvoicePage} />} />
      <Route path="/subscriptions/:userId" element={<PrivateRoute component={SubscriptionsUserPage} />} />
      <Route path="/seller/subscribers/:productId" element={<PrivateRoute component={SubscriptionsProductPage} />} />
      <Route path="/invoicesCompany/:companieId" element={<PrivateRoute component={InvoicesCompaniePage} />} />
      <Route path="/invoices/:companieId/:userId" element={<PrivateRoute component={InvoicesUserPage} />} />
      <Route path="/invoices" element={<PrivateRoute component={InvoicesPage} />} />
      {/* <Route path="/perk/:perkId" element={<PrivateRoute component={PerkPageBuyer} />} /> */}
      {/* <Route path="/perks/:partnerId" element={<PrivateRoute component={PerksBuyerPage} />} /> */}
      <Route path="/seller/payments/:productId" element={<PrivateRoute component={InvoicesSellerPage} />} />
      <Route path="/statements/:companieId" element={<PrivateRoute component={PostPaidStatementsPage} />} />
      <Route path="/subscriptionsCompany/:companieId" element={<PrivateRoute component={SubscriptionsCompanyPage} />} />
      <Route path="/subscriptionsCompanySheet/:companieId" element={<PrivateRoute component={SubscriptionsCompanySheetPage} />} />
      <Route path="/buyer/subscriptions" element={<PrivateRoute component={SubscriptionsCompanyRedirect} />} />
      <Route path="/messages" element={<PrivateRoute component={ChatPage} />} />
      <Route path="/changePhone/:userId" element={<PrivateRoute component={ChangePhonePage} />} />
      <Route path="/seller/reviewRequest" element={<PrivateRoute component={ReviewRequestSellerPage} />} />
      <Route path="/seller/servicePartners" element={<PrivateRoute component={ServicePartnerSellerPage} />} />
      <Route path="/maven/reviewRequest" element={<PrivateRoute component={ReviewRequestMavenPage} />} />
      <Route path="/maven/leads" element={<PrivateRoute component={CompanieMavenLeads} />} />
      <Route path="/maven/customers" element={<PrivateRoute component={MavenCustomersTabPage} />} />
      <Route path="/inviteBuyer" element={<PrivateRoute component={InviteBuyerRedirectPage} />} />
      <Route path="/cashback" element={<PrivateRoute component={CashbackPage} />} />
      <Route
        path="/seller/revenueShare/:companieId/:productId"
        element={<PrivateRoute component={CompanieSellerRevenueShare} />}
      />
      <Route path="/seller/leads" element={<PrivateRoute component={CompanieSellerLeads} />} />
      <Route path="/seller/product/:productId" element={<PrivateRoute component={ProductPageSeller} />} />
      <Route path="/seller/analytics/:productId" element={<PrivateRoute component={SellerAnalyticPage} />} />
      <Route path="/subscribe/:productId" element={<PrivateRoute component={SubscribeToProduct} />} />
      <Route path="/disputes/:companieId" element={<PrivateRoute component={DisputeIssuingsCompaniePage} />} />
      <Route path="/feed/:companieId" element={<PrivateRoute component={EventFeedPage} />} />
      <Route path="/shaman" element={<PrivateRoute component={ShamanPage} />} />
      <Route path="/tutorial" element={<PrivateRoute component={TutorialPage} />} />
      <Route path="/profile" element={<PrivateRoute component={UserProfileRedirect} />} />
      <Route path="/sherlock" element={<PrivateRoute component={SherlockPage} />} />
      {/* <Route path="/sherlock/:companieId" element={<PrivateRoute component={ExpensesDiscovery} />} /> */}
      <Route path="/seller/perks/:productId" element={<PrivateRoute component={PerksSellerPage} />} />
      <Route path="/seller/perkDetail/:perkDetailId" element={<PrivateRoute component={PerkDetailPageSeller} />} />
      <Route path="/tribe/perkDetailOffer/:perkDetailId" element={<PrivateRoute component={PerkDetailPageTribe} />} />
      <Route path="/balanceAdmin" element={<AdminRoute component={BalanceAdmin} />} />
      <Route path="/admin/user/:userId" element={<AdminRoute component={UserProfileDetailsAdminPage} />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default RouteApp
