import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Product } from '__generated__/graphql'

type Props = {
  product: Pick<Product, 'id'>
}
const MavenVerificationRequestSubmitted = (props: Props) => {
  const productId = props.product.id
  return (
    <>
      <div className="titleStepKYC">Thats it!</div>
      <div
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        <p>
          We will now review your application, and reach out to you if we have any questions. Otherwise, your Maven Profile will
          get listed in our Maven Marketplace and we'll start promoting you to your ideal customers.
        </p>
        <p>In the meantime:</p>
        <ul>
          <li>
            <b>Add Special Offers</b> (in Profile tab): any incentives, freebies, discounts, promotions you'd like to offer to
            NachoNacho customers.
          </li>
          <li>
            <b>Add Quotations</b> (in Profile tab): provide details of specific projects you can work on, with clear deliverables,
            and time and cost estimates.
          </li>
          <li>
            <b>Add Customers</b> (in Profile tab): list your past and current customers.
          </li>
          <li>
            <b>Invite Reviewers:</b> you can start collecting reviews from your customers. Reviews are a critical element of your
            Profile - they create social proof and provide credibility for prospective customers.
          </li>
        </ul>
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          <Link className="link" to="/maven/reviewRequest">
            <Button id="createBuyerCompany" size="medium" color="primary" variant="contained">
              Invite Reviewers
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}
export default MavenVerificationRequestSubmitted
