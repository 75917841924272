import React from 'react'
import { Companie } from '__generated__/graphql'
import { Icon, Dialog, Button } from '@mui/material'
import AddSourcePageStripe from '../single/AddSourcePageStripe'

type Props = {
  companie: Pick<
    Companie,
    | 'id'
    | 'requestCardVerification'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const AddPaymentSourceLogicStripeMaven = (props: Props) => {
  const [paymentMethod, setPaymentMethod] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const onOpen = (paymentMethod: string) => {
    setPaymentMethod(paymentMethod)
    setOpen(true)
  }
  return (
    <>
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <AddSourcePageStripe
          requestCardVerification={false}
          companie={props.companie}
          paymentMethod={paymentMethod}
          onCancel={onClose}
          onSuccess={onClose}
        />
      </Dialog>

      <Button id="btn-add-credit-card" onClick={() => onOpen('creditCard')} color="primary" variant="outlined">
        + Credit/Debit card
        <div style={{ width: '10px' }} />
        <Icon>credit_card</Icon>
      </Button>
    </>
  )
}

export default AddPaymentSourceLogicStripeMaven
