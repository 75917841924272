import { useAppContext } from 'components/AppContext'
import { Avatar, Box, Button, Menu, MenuItem } from '@mui/material'
import React from 'react'
import utils from 'components/utils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Link } from 'react-router-dom'
import { ReactComponent as HomepageIcon } from 'assets/icon/profile-menu/homepage.svg'
import { ReactComponent as LogoutIcon } from 'assets/icon/profile-menu/logout.svg'
import { ReactComponent as SettingsLightIcon } from 'assets/icon/settingsLightIcon.svg'
import { ReactComponent as ProfileIcon } from 'assets/icon/profileIcon.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/icon/externalLinkIcon.svg'
import { ReactComponent as MailIcon } from 'assets/icon/mailIcon.svg'
import { URL_HOME } from 'config/config'
import theme from 'components/themeNN'
import themeNN from 'components/themeNN'

const ProfileMenu = () => {
  const { logout, userRoleCompanie, me } = useAppContext()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const styleLabel = { fontSize: '14px', fontWeight: 400 }

  return (
    <Box bgcolor="white" borderRadius="99px">
      <Button
        id="profile-menu-btn"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ p: '5px' }}
      >
        <Box display="flex" gap="6px" alignItems="center" sx={{ color: 'black', fontWeight: 400 }}>
          <Avatar src={utils.getUrlFileMedia(me.nameFile)} sx={{ width: 28, height: 28 }} />
          <span>
            {me.firstName} {me.lastName}
          </span>
          <KeyboardArrowDownIcon sx={{ fontSize: '18px' }} />
        </Box>
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'profile-menu-btn' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { borderRadius: '8px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)', minWidth: '152px' } }}
        disableScrollLock
      >
        {userRoleCompanie?.companie?.id && (
          <Link to={`/userCompany/${me.id}/${userRoleCompanie.companie.id}`}>
            <MenuItem id="id-profileMenu-Profile" onClick={handleClose}>
              <Box display="flex" gap="8px">
                <Box width="20px" textAlign="center">
                  <ProfileIcon width={16} height={16} fill={themeNN.lightGrey.color} />
                </Box>
                <span style={styleLabel}>Profile</span>
              </Box>
            </MenuItem>
          </Link>
        )}

        <Link to="/settings/user">
          <MenuItem id="id-profileMenu-profileSettings" onClick={handleClose}>
            <Box display="flex" gap="8px">
              <Box width="20px" textAlign="center">
                <SettingsLightIcon width={16} height={16} fill={themeNN.lightGrey.color} />
              </Box>
              <span style={styleLabel}>Personal Settings</span>
            </Box>
          </MenuItem>
        </Link>

        <a href={URL_HOME} target="_blank" rel="noreferrer">
          <MenuItem id="id-profileMenu-homepage" onClick={handleClose}>
            <Box display="flex" gap="8px">
              <Box width="20px" textAlign="center">
                <HomepageIcon width={16} height={16} fill={themeNN.lightGrey.color} />
              </Box>
              <span style={styleLabel}>Homepage</span>
              <Box style={{ marginTop: '-2px' }}>
                <ExternalLinkIcon width={10} height={10} />
              </Box>
            </Box>
          </MenuItem>
        </a>

        <a href={`${URL_HOME}/contact`} target="_blank" rel="noreferrer">
          <MenuItem id="id-profileMenu-help" onClick={handleClose}>
            <Box display="flex" gap="8px">
              <Box width="20px" textAlign="center">
                <MailIcon width={16} height={16} fill={themeNN.lightGrey.color} />
              </Box>
              <span style={styleLabel}>Contact us</span>
              <Box style={{ marginTop: '-2px' }}>
                <ExternalLinkIcon width={10} height={10} />
              </Box>
            </Box>
          </MenuItem>
        </a>

        <MenuItem
          id="id-profileMenu-logout"
          onClick={() => {
            handleClose()
            logout(['app', 'home'])
          }}
        >
          <Box display="flex" gap="8px">
            <Box width="20px" textAlign="center">
              <LogoutIcon width={16} height={16} fill={themeNN.lightGrey.color} />
            </Box>
            <span style={{ ...styleLabel, color: theme.error.color }}>Log out</span>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ProfileMenu
